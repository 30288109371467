import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { Projects } from "./pages/Projects";

const App = () => {
  return (
    <Fragment>
      <Router>
        <Navbar />
        <Route exact path="/" component={Home} />
        <Route
          render={() => (
            <Fragment>
              <Switch>
                <Route path="/projects" component={Projects} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
              </Switch>
            </Fragment>
          )}
        />
      </Router>
    </Fragment>
  );
};

export default App;
