import React from "react";
import ReactLogo from "../assets/react-small.svg";
import TailwindLogo from "../assets/tailwindcss.svg";
import NodeLogo from "../assets/node.svg";
import ExpressLogo from "../assets/express.svg";
import MongoLogo from "../assets/mongodb.svg";
import GitHubLogo from "../assets/github.svg";
import MaterialUiLogo from "../assets/material-ui.svg"
import { Link } from "react-router-dom";

export const Projects = () => {
  return (
    <div className="grid grid-cols-12 gap-4 mx-3 my-10">
      <div className="col-start-1 col-span-7">
        <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl mx-10 ">
          <span className="block xl:inline">Projects</span>
        </h1>
      </div>
      <div className="inline align-middle col-start-2 col-end-12 border border-gray-100 shadow-md p-1 my-7">
        <div className="my-4">
          <h1 className="inline align-middle text-2xl tracking-tight font-extrabold text-gray-800 sm:text-2xl md:text-2xl mx-4">
            This Portfolio Site
          </h1>
          <a href="https://github.com/djmartin2019/Portfolio">
            <img
              className="inline"
              width={30}
              height={30}
              src={GitHubLogo}
              alt="GitHub Logo"
            />
          </a>
        </div>
        <div className="flex flex-wrap mx-10 mt-5">
          <p>
            This website will continue to grow as I upload more projects and
            content, but the code can be found on my GitHub account!
          </p>
        </div>
        <div className="flex flex-wrap">
          <h1 className="text-xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 mt-5">
            <span className="block xl:inline">Technologies Used</span>
          </h1>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ReactLogo}
              alt="React Logo"
            />
            React
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={TailwindLogo}
              alt="Tailwind Logo"
            />
            Tailwindcss
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={NodeLogo}
              alt="Node Logo"
            />
            Node.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ExpressLogo}
              alt="Express Logo"
            />
            Express.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline place-self-center"
              width={24}
              height={23}
              src={NodeLogo}
              alt="NodeMailer Logo"
            />
            NodeMailer
          </div>
        </div>
        <div className="grid grid-cols-5">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 my-5">
            <span className="block xl:inline">Progress</span>
          </h1>
        </div>
      </div>
      <div className="col-start-2 col-span-7">
        <h1 className="text-3xl tracking-tight font-extrabold text-gray-800">
          <span className="block xl:inline">In The Works</span>
        </h1>
      </div>
      <div className="col-start-2 col-end-12 border border-gray-100 shadow-md p-1 my-7">
        <div className="flex flex-wrap">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 sm:text-2xl md:text-2xl mx-4 my-2">
            Local Foodie
          </h1>
          {/* <img width={30} height={30} src={GitHubLogo} alt="React Logo" /> */}
        </div>
        <div className="flex flex-wrap mx-10 mt-5">
          <p>
            This is a Yelp clone that I’m working on but it will focus on local
            business and small chains.
          </p>
        </div>
        <div className="flex flex-wrap">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 mt-5">
            <span className="block xl:inline">Technologies Used</span>
          </h1>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ReactLogo}
              alt="React Logo"
            />
            React
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={MaterialUiLogo}
              alt="Material-UI Logo"
            />
            Material-UI
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={NodeLogo}
              alt="Node Logo"
            />
            Node.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ExpressLogo}
              alt="Express Logo"
            />
            Express.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline place-self-center"
              width={24}
              height={23}
              src={NodeLogo}
              alt="NodeMailer Logo"
            />
            NodeMailer
          </div>
        </div>
        <div className="grid grid-cols-5">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 my-5">
            <span className="block xl:inline">Progress</span>
          </h1>
        </div>
      </div>
      <div className="col-start-2 col-end-12 border border-gray-100 shadow-md p-1 my-7">
        <div className="flex flex-wrap">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 sm:text-2xl md:text-2xl mx-4 my-2">
            Entertainment App
          </h1>
          {/* <img width={30} height={30} src={GitHubLogo} alt="React Logo" /> */}
        </div>
        <div className="flex flex-wrap mx-10 mt-5">
          <p>
            This is an inventory management app focused on ensuring you’re
            always stocked on supplies for your next event.
          </p>
        </div>
        <div className="flex flex-wrap">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 mt-5">
            <span className="block xl:inline">Technologies Used</span>
          </h1>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ReactLogo}
              alt="React Logo"
            />
            React
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={TailwindLogo}
              alt="Tailwind Logo"
            />
            Tailwindcss
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={NodeLogo}
              alt="Node Logo"
            />
            Node.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ExpressLogo}
              alt="Express Logo"
            />
            Express.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline place-self-center"
              width={24}
              height={23}
              src={NodeLogo}
              alt="NodeMailer Logo"
            />
            NodeMailer
          </div>
        </div>
        <div className="grid grid-cols-5">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 my-5">
            <span className="block xl:inline">Progress</span>
          </h1>
        </div>
      </div>
      <div className="col-start-2 col-end-12 border border-gray-100 shadow-md p-1 my-7">
        <div className="flex flex-wrap">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 sm:text-2xl md:text-2xl mx-4 my-2">
            Photography Blog
          </h1>
          {/* <img width={30} height={30} src={GitHubLogo} alt="React Logo" /> */}
        </div>
        <div className="flex flex-wrap mx-10 mt-5">
          <p>
            I recently got into photography after getting my hands on a Cannon
            camera. I plan to start a blog where I document all of the
            adventures I take with my camera.
          </p>
        </div>
        <div className="flex flex-wrap">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 mt-5">
            <span className="block xl:inline">Technologies Used</span>
          </h1>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ReactLogo}
              alt="React Logo"
            />
            React
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={TailwindLogo}
              alt="Tailwind Logo"
            />
            Tailwindcss
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={NodeLogo}
              alt="Node Logo"
            />
            Node.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline"
              width={24}
              height={23}
              src={ExpressLogo}
              alt="Express Logo"
            />
            Express.js
          </div>
          <div className="w-1/2 overflow-hidden sm:my-1 sm:px-1">
            <img
              className="mx-2 inline place-self-center"
              width={24}
              height={23}
              src={NodeLogo}
              alt="NodeMailer Logo"
            />
            NodeMailer
          </div>
        </div>
        <div className="grid grid-cols-5">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-800 sm:text-xl md:text-xl mx-4 my-5">
            <span className="block xl:inline">Progress</span>
          </h1>
        </div>
      </div>
      <div className="col-start-2 col-span-7">
        <h1 className="text-3xl tracking-tight font-extrabold text-gray-800">
          <span className="block xl:inline">Future Plans</span>
        </h1>
      </div>
      <div className="col-start-2 col-end-12 border border-gray-100 shadow-md p-1 my-7">
        <div className="flex flex-wrap my-4">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-800 sm:text-2xl md:text-2xl mx-2">
            <span className="block xl:inline">Non-Profit Websites</span>
          </h1>
        </div>
        <div className="flex flex-wrap mx-10 mt-5 mb-4">
          <p>
            Once I have my personal websites up and running, I would like to use
            my web development skills to help develop websites for local
            non-profits.
          </p>
        </div>
      </div>
    </div>
  );
};
