import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">David Martin</span>
            <br />
            <span className="block text-blue-900 xl:inline">
              Aspiring web developer
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            I am a graduate from the C.T. Bauer College of Business at the
            University of Houston with a BBA in MIS. I have a strong passion for
            learning new technologies and using them to develop modern web
            applications.
          </p>
          <Link to="/projects">
            <button className="bg-blue-900 hover:bg-blue-700 text-white font-bold mr-25 mt-4 py-2 px-4 rounded">
              View My Projects
            </button>
          </Link>
        </div>
      </main>
    </div>
  );
};
